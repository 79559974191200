.card_back{
    background-color: white;
    padding:60px;
}
.tranin-icon{
    height:70px
}
.traning_card{ 
    padding: 20px;
    background-color: white;
    border-bottom: 4px solid #00deff;
    box-shadow: 0 5px 20px 0 rgba(23, 44, 82, 0.1);
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    margin-top: 30px;
}
.card-heading{
    font-size:17px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 10px;

}
.card-text-traning{
    font-size:14px;
    color: #666;
    text-align: left;
    letter-spacing: 1px;
    margin-top: 10px;

}
@media (max-width:768px){
    .card_back {
        background-color: white;
        padding: 15px;
    }
    .card-text-traning {
        font-size: 13px;
        color: #666;
        text-align: left;
        letter-spacing: 1px;
        margin-top: 10px;
    }
}