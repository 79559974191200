.traning_back_img{
    /*background: #211E3B;*/
      background-image: url("../../../public/img/traning-bck-img.png");
      height: auto;
      margin-top: 0px;
      padding: 40px 60px;
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;
      
  }
  .overlay_theme{
    position: absolute;
    opacity: 0.7;
    background: #000000;
    color: #f1f1f1;
    left: 0;
    top: 0;
    width: 100%;
    padding: 40px;
    height: 100%;
    
  }

.traning_heading{
    color: #FFFFFF;
    font-family: "Montserrat", Montserrat;
    font-size: 50px;
    font-weight: 600;
    line-height: 1.3em;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 20px;
    text-align: left;
}
.no-heading{
    color: #FFFFFF;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1em;
    letter-spacing: 0.5px;
    margin-top: 40px;
}
.frm-txt{
    color: #00deff;
    font-family: "Montserrat", Montserrat;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: 1.3px;
    margin-bottom: 20px;
}
.frm_bck1{
    z-index: 99;
}
.frm_bck{ 
     background-color: rgb(0 0 0 / 62%);;
    padding: 20px;
    
} 
.traning_heading-clr{
    color: #00deff;
}
 .frm-field{
    border:none;
    width:100%;
    padding:8px;
    border-radius:5px;
    margin-bottom:20px;
    outline:none !important;
    text-decoration:none !important;
}
.lab-eel{
    color:white;
    font-weight:700;
    font-size:17px;
    margin-bottom:7px;
}
.skd_btn{
    height:40px;
    width:80%;
    color:white;
    background:transparent;
    border:1px solid white;
    font-size:18px;
    border-radius:5px;
    margin-top:10px;
}
.download-brocher{
    height:40px;
    width:25%;
    color:#00deff;
    background:transparent;
    border:1px solid #00deff;
    font-size:18px;
    border-radius:5px;
    margin-top:20px;
}
.download-brocher:hover{
    height:40px;
    width:25%;
    color:white;
    background:#00deff;
    border:none;
    font-size:18px;
    border-radius:5px;
    margin-top:20px;
}
::placeholder {
    color: black;
  }
@media (max-width:991px) {
    .traning_heading {
        color: #FFFFFF;
        font-family: "Montserrat", Montserrat;
        font-size: 30px;
    }
    .frm_bck {
        background-color: rgb(0 0 0 / 62%);
        padding: 20px;
        margin-top: 47px;
    }

}
@media (max-width:768px) {
    .traning_back_img{
        /*background: #211E3B;*/
        background-image: url("../../../public/img/traning-bck-img.png");
        height: auto;
          margin-top: 0px;
          padding: 40px 15px;
          width: 100%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
      }
    .no-heading {
        color: #FFFFFF;
        font-family: "Montserrat";
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.5px;
        margin-top: 0px;
    }
    .traning_heading {
        color: #FFFFFF;
        font-family: "Montserrat", Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 20px;
        text-align: left;
    }
    .download-brocher {
        height: 40px;
        width: 60%;
        color: #00deff;
        background: transparent;
        border: 1px solid #00deff;
        font-size: 18px;
        border-radius: 5px;
        margin-top: 20px;
    }
    .download-brocher:hover{
        height:40px;
        width:60%;
        color:white;
        background:#00deff;
        border:none;
        font-size:18px;
        border-radius:5px;
        margin-top:20px;
    }
    .frm_bck {
        background-color: rgb(0 0 0 / 62%);
        padding: 20px;
        margin-top: 30px;
    }
    .frm-txt {
        color: #00deff;
        font-family: "Montserrat", Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2em;
        letter-spacing: 1.3px;
        margin-bottom: 20px;
    }
}
@media (max-width:768px) {
    .logo_navbar {
        height: 38px;
    }

}