.price-bck{
    padding: 80px 80px;
}
.course-crd{
    background-color: white;
    padding: 25px;
    box-shadow: 0 5px 20px 0 rgba(23, 44, 82, 0.1);
    border-radius: 5px;
    margin-top:30px;
    width: 100%;
}
.crd-headder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}
.my_tflax
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.hedr-txt{
    color: black;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 27px;
    text-align: left;
    margin-top: 10px;
}
.sub-hedr-txt{
    color: #666;
    float: right;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
}
.price-icon{
    height: 50px;
}
.ic-crcl{
    border-radius: 50%;
    border: 2px solid black;
    padding: 10px;
}
.name-btn {
    font-size: 13px;
    color: black;
    background-color: #00deff6b;
    padding: 5px;
    /* color: white; */
}
.tec-part{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    flex-wrap:wrap;
    overflow-wrap: break-word;
    margin-top: 10px;
    letter-spacing: 1px;
}
.tech-heading{
    font-size: 17px;
    font-weight: 500;
    margin-top: 15px;
}
.btn-flx{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.course-btn{
    width:40%;
    height:35px;
    border: none;
    background: rgb(12, 12, 12);
    color: white;
    font-size: 17px;
    /* border-radius: 5px; */
    margin-top: 20px;
}
@media(max-width:768px){
    .price-bck {
        padding: 40px 20px;
    }
}