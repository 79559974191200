.why-bck{
    padding: 40px 0%;
    overflow: hidden;
}
.txt-padding{
    padding:0px 40px
}
.hed-ing_why{
    color: #222222;
    font-family: "Montserrat", Montserrat;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    line-height: 40px;
}
.listyle{
    list-style-type:disc;
    padding-left: 30px;
    margin-top:20px
}
.why-txt{
    font-size:15px;
    margin-top: 10px;
    letter-spacing: 1px;
}
.main-txt{
    font-size: 17px;
    margin-top:10px;
    letter-spacing: 1px;
}
.fls-strt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
@media(max-width:991px){

    .txt-padding {
        padding: 0px 40px;
        margin-top: 40px;
    }

}
@media(max-width:768px){
    .hed-ing_why {
        color: #222222;
        font-family: "Montserrat", Montserrat;
        font-size: 22px;
        font-weight: 600;
        text-align: left;
        line-height: 31px;
    }
    .main-txt {
        font-size: 15px;
        margin-top: 10px;
        letter-spacing: 1px;
    }
    .why-txt {
        font-size: 14px;
        margin-top: 10px;
        letter-spacing: 1px;
    }
    .listyle {
        list-style-type: disc;
        padding-left: 20px;
        margin-top: 20px;
    }
}