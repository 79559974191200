@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.bg-light {
    background-color: white!important;
  }
  
  .logo_navbar {
    height: 50px;
  }
  .btn-enr-oll{
    height: 40px;
    
    color: black;
    background: transparent;
    border: 2px solid black;
    font-size: 20px;
    border-radius: 5px;
    float: right;
    font-weight: 500;
    padding-left:20px;
    padding-right:20px;
  }
  .btn-enr-oll:hover{
    height: 40px;
    padding-left:20px;
    padding-right:20px;
    color: white;
    background-color: black;
    border: 2px solid black;
    font-size: 20px;
    border-radius: 5px;
    float: right;
    /* padding: 0px 10px; */
    font-weight: 500;
  }
  .nav-link {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: black !important;
    text-transform: uppercase;

  }
  
  .nav-link:hover {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #43baff !important;
    transition: 0.8s;
  }
  
  .dropdown-item {
    font-size: 15px !important;
    /* font-weight: 500 !important; */
    color: black !important;
  }
  
  .dropdown-item:hover {
    color: #43baff !important;
    text-decoration: none;
    background-color: #ececec;
    transition: 0.8s;
    border-left: 5px solid #43baff;
    font-size: 15px !important;
    /* font-weight: 500 !important; */
  }
  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    padding: 10px;
}
  .pl-lg-4{
    padding-left: 26rem!important;

  }
  .px-lg-4 {
    padding-left: 30rem!important;
  }
  
  .dropdown-toggle::after {
    display: none;
  }
  
  .text-decoration-none {
    text-decoration: none!important;
  }
  
  /* Font Awesome Icons have variable width. Added fixed width to fix that. */
  .icon-width {
    width: 2rem;
  }
  .top_flax_nav
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:30px;
    padding-top:15px;
    padding-bottom:15px;
  }

  @media (max-width: 1400px) {
    .pl-lg-4 {
      padding-left: 24rem !important;
    }
    /* .overlay_theme {
      position: absolute;
      opacity: 0.7;
      background: #000000;
      color: #f1f1f1;
      left: 0;
      top: 0;
      width: 100%;
      padding: 40px;
      height: 89vh;
      margin-top: 76px;
  } */
  .btn-enr-oll {
    height: 40px;
    color: black;
    background: transparent;
    border: 2px solid black;
    font-size: 16px;
    border-radius: 5px;
    float: right;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
}
  }
    @media (max-width: 1000px) {
      .nav-link {
        font-size: 12px !important;
        font-weight: 500 !important;
        color: black !important;
        text-transform: uppercase;
    }
    }



  @media (max-width: 1200px) {
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 8.5rem!important;
    }
  }
  
  @media (max-width: 992px) {
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 0rem!important;
    }
  }
  