
.crd2-bck{
    background-color: black;
    padding: 80px 80px;
    margin-top:40px
}
.simpel-crd{
    border: 1px solid white;
    padding: 30px;
    border-radius: 5px;
   min-height: 1px;
}
.scnd-crd-icon{
    height: 70px;
}
.crd-main-heading{
    font-size:17px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 10px;
    color: white;
}
.crd-sub-heading{
    font-size:14px;
    color: #666;
    text-align: left;
    letter-spacing: 1px;
    margin-top: 10px;
    color: white;
    
}
@media(max-width:991px){
    .simpel-crd 
        {
            margin-bottom:20px
        }
}
@media(max-width:768px){
    
    .crd2-bck {
        background-color: black;
        padding: 20px 20px 35px 20px;
        margin-top: 0px;
    }
    .simpel-crd {
        border: 1px solid white;
        padding: 30px;
        border-radius: 5px;
        min-height: 1px;
        margin-top: 20px;
    }
    .txt-padding {
        padding: 20px 20px;
    }
    .why-bck {
        padding: 40px 15px;
    }
}