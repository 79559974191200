@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

p {
    padding: 0%;
    margin: 0%;
}

.bck-clr {
    background: black;
    /*background-image: url("img/payalfooter.png");*/
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin-top: 100px;
    padding: 60px 80px;
}

a {
    text-decoration: none !important;
    outline: none !important;
}

.logo_img {
    height: 80px;
    margin-bottom: 30px;
}

.ri-global-line {
    color: #43baff;
    font-size: 30px;
}

.ri-mail-fill {
    color: #43baff;
    font-size: 25px;
}

.ri-phone-fill {
    color: #43baff;
    font-size: 25px;
}

.address {
    /* text-align:center; */
    font-size: 13px;
    color: white;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;

}

.ftr-txt-top {
    margin-top: 15px;
}

.footer_tabs {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none !important;
    outline: none !important;
    padding-top: 5px;
    letter-spacing: 1px;
}

.footer_tabs:hover {
    font-size: 13px;
    font-weight: 400;
    color: #43baff;
    text-transform: uppercase;
    text-decoration: none !important;
    outline: none !important;
}

.bck-copy-right {
    background: #020101cf;
    width: 100%;
    margin-top: 0px;
    padding: 20px;
}

.copy_right {
    font-size: 16px;
    font-weight: 500;
    color: #ada9a9;
    text-align: left;
    text-transform: uppercase;

}

.padd {
    padding: 20px;
}

.footer-flx {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 30px;
}

.head-ingtop {
    color: white;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
}

@media(max-width:991px) {
    .myf_top {
        margin-top: 20px;
    }

}

@media(max-width:768px) {
    .padd {
        padding: 0px;
    }

    .address {
        font-size: 12px;
        color: white;
        font-weight: 400;
        letter-spacing: 1px;
        word-break: break-all;
    }

    .footer_tabs {
        font-size: 11px;
        font-weight: 500;
        color: #fff;
        text-decoration: none !important;
        outline: none !important;
        margin-top: 10px;
    }

    .bck-clr {
        background: black;
        /* background-image: url(img/payalfooter.png); */
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        margin-top: 100px;
        padding: 20px 20px;
    }

    .footer-flx {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 15px;
    }

    .dis {
        display: none;
    }

    .copy_right {
        font-size: 12px;
        font-weight: 500;
        color: #ada9a9;
        text-align: left;
        text-transform: uppercase;
    }
}